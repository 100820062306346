// extracted by mini-css-extract-plugin
export var clientsSection = "client-module--clients-section--18zGH";
export var clientsBox = "client-module--clients-box--1KbYD";
export var headingWrap = "client-module--heading-wrap--3PLy2";
export var clientsIcon = "client-module--clients-icon--3mwU2";
export var clientHeading = "client-module--client-heading--1FlhR";
export var blueBox = "client-module--blue-box--2vBt3";
export var latteBox = "client-module--latte-box--18rLX";
export var gradientBox = "client-module--gradient-box--3EcRC";
export var canaryBox = "client-module--canary-box--pdGnO";
export var aliceblueBox = "client-module--aliceblue-box--3ZGYO";
export var firstClientCol = "client-module--first-client-col--2dzzI";
export var thirdClientCol = "client-module--third-client-col--1mESL";