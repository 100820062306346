import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import StarsIcon from '../icons/starsIcon';
import {
  testimonySection,
  testimonyBox,
  testimonyItem,
  profilePicWrap,
  profilePic,
  hospital,
  stars,
} from './testimony.module.scss';

function Testimony() {
  return (
    <section className={testimonySection}>
      <div className={testimonyBox}>
        <div className={testimonyItem}>
          <div className={profilePicWrap}>
            <StaticImage
              className={profilePic}
              src='../../images/profile1.jpg'
              alt='profile picture'
            />
          </div>
          <div>
            <StarsIcon className={stars} />
          </div>
          <h4>Dr Satish Patil</h4>
          <p className={`small-text ${hospital}`}>Patil Hospital</p>
          <p className='small-text'>
            "A unique platform for your hospital that simplifies communication
            and makes scheduling easier than ever."
          </p>
        </div>
        <div className={testimonyItem}>
          <div className={profilePicWrap}>
            <StaticImage
              className={profilePic}
              src='../../images/profile2.jpg'
              alt='profile picture'
            />
          </div>
          <div>
            <StarsIcon className={stars} />
          </div>
          <h4>Dr. Sagar Khedu</h4>
          <p className={`small-text ${hospital}`}>Morya Hospital</p>
          <p className='small-text'>
            "Daily Doc changing how we work in the hospitals. Minimize
            interruptions and improve accountability"
          </p>
        </div>
        <div className={testimonyItem}>
          <div className={profilePicWrap}>
            <StaticImage
              className={profilePic}
              src='../../images/profile3.jpg'
              alt='profile picture'
            />
          </div>
          <div>
            <StarsIcon className={stars} />
          </div>
          <h4>Dr. Adish Patil </h4>
          <p className={`small-text ${hospital}`}>Patil Hospital</p>
          <p className='small-text'>
            "Good step in right direction. Daily Doc changing the hospital work
            flow in a positive way"
          </p>
        </div>
      </div>
    </section>
  );
}

export default Testimony;
