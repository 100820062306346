import React from 'react';

function Feature2Sketch({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='531.744'
      height='503'
      viewBox='0 0 531.744 503'
    >
      <defs>
        <filter
          id='a'
          x='145'
          y='45'
          width='264'
          height='425'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='6' result='b' />
          <feFlood floodOpacity='0.239' />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='c'>
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='8.5' result='d' />
          <feFlood floodColor='#fff' floodOpacity='0.541' result='e' />
          <feComposite operator='out' in='SourceGraphic' in2='d' />
          <feComposite operator='in' in='e' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
        <filter
          id='f'
          x='299.5'
          y='145.5'
          width='232.244'
          height='230.35'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='9' dy='9' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='18.5' result='g' />
          <feFlood floodOpacity='0.078' />
          <feComposite operator='in' in2='g' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(-1243 -7091)'>
        <circle
          cx='251.5'
          cy='251.5'
          r='251.5'
          transform='translate(1258 7091)'
          fill='#f5f7fa'
        />
        <g transform='translate(1243 7096)'>
          <path d='M0,0H492V492H0Z' fill='none' fillRule='evenodd' />
          <g transform='translate(157 52)'>
            <g transform='matrix(1, 0, 0, 1, -157, -57)' filter='url(#a)'>
              <rect
                width='228'
                height='389'
                rx='8'
                transform='translate(157 57)'
              />
            </g>
            <rect width='228' height='389' rx='8' fill='#e9f7fd' />
            <g transform='matrix(1, 0, 0, 1, -157, -57)' filter='url(#c)'>
              <rect
                width='228'
                height='389'
                rx='8'
                transform='translate(157 57)'
                fill='#fff'
              />
            </g>
          </g>
          <path
            d='M8,0H220a8,8,0,0,1,8,8V24H0V8A8,8,0,0,1,8,0Z'
            transform='translate(157 52)'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <path
            d='M471,85H242a4,4,0,0,0-4,4v39.143a4,4,0,0,0,4,4h24.636a4,4,0,0,1,2.6.964l7.389,6.335a1,1,0,0,0,1.3,0l7.391-6.336a4,4,0,0,1,2.6-.963H471a4,4,0,0,0,4-4V89A4,4,0,0,0,471,85Z'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <path
            d='M21,214H312a4,4,0,0,1,4,4v51.143a4,4,0,0,1-4,4H279.479a4,4,0,0,0-2.6.956l-9.793,8.348a1,1,0,0,1-1.3,0L256,274.1a4,4,0,0,0-2.6-.956H21a4,4,0,0,1-4-4V218A4,4,0,0,1,21,214Z'
            fill='#5c9640'
            fillRule='evenodd'
          />
          <path
            d='M395,368H252a4,4,0,0,0-4,4v22a4,4,0,0,0,4,4h13.71a4,4,0,0,1,2.6.962l4.067,3.481a1,1,0,0,0,1.3,0l4.066-3.481a4,4,0,0,1,2.6-.962H395a4,4,0,0,0,4-4V372A4,4,0,0,0,395,368Z'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <g transform='translate(346 187)'>
            <g transform='matrix(1, 0, 0, 1, -346, -192)' filter='url(#f)'>
              <ellipse
                cx='60.622'
                cy='59.675'
                rx='60.622'
                ry='59.675'
                transform='translate(346 192)'
              />
            </g>
            <ellipse
              cx='60.622'
              cy='59.675'
              rx='60.622'
              ry='59.675'
              transform='translate(0 0)'
              fill='#5c9640'
            />
            <path
              d='M40.249,35.79c-3.146,5.767-2.772,20.394,1.294,21.424,1.621.411,5.075-.6,8.59-2.679L47.927,69.887h13.03l-4.486-20.56a14.322,14.322,0,0,0,3.912-10.391c-.816-11.425-16.988-8.912-20.134-3.146Zm59.323,64.739-9.456,2.75q18.228,28.209,21.18,32.47l.124.177c.155.221.27.38.344.474,2.091,2.666.822,8,.424,10.785,4.07.574,1.976-5.26,7.133-4.457q1.018.165,2.02.41l.5.12.491.123.488.122c3.318.825,6.38,1.438,9.327-1.78.486-.53.741-2.078-1.128-2.7-4.655-1.54-11.6-4.58-12.8-5.892q-2.45-2.685-18.644-32.6ZM25.925,117l-14.5,16.179a47.345,47.345,0,0,0-10.68,7.14c-.6.825-1.2,2.368,1.812,1.485s6.218-1.678,6.862-.73-.945,2.583.207,3.8q1.152,1.22,6.572-8.736l15.51-10.618L25.925,117Z'
              fill='#fba9ab'
              fillRule='evenodd'
            />
            <path
              d='M53.732,67.074l-6.257-1.126c-5.281,21.292-14.64,42.49-32.542,61.8l6.2,7.874c16.3-12.9,31.987-23.352,36.617-39.927,1.554-5.562-2.955-22.382-4.023-28.622Z'
              fill='#fff'
              fillRule='evenodd'
            />
            <path
              d='M82.245,120.9q-25.318,6.321-40.455,5.685c-1.25-.052-1.728-2.054-1.356-3.51,5.788-22.683,9.194-42.244,7.1-57.14q2.595.876,8.531-.827c13.01,13.825,20.473,30.737,26.18,55.792Z'
              fill='#2d3b5f'
              fillRule='evenodd'
            />
            <path
              d='M58.527,63.167l.056.277-4.523.637c-.186,6.243,8.044,88.5,1.98,118.008l-.189.878,55.48-7.8c-4.715-24.821-18.422-53.507-31.564-77.277q14.379,9.809,27.747,36.186l10.019-4.113q-18.57-48.03-57.916-66.3l-.208-.337-.4.056-.48-.218Z'
              fill='#fff'
              fillRule='evenodd'
            />
            <path
              d='M48.919,28.139c1.727-.27,6.38-.455,7.237-.455.549,0,1.143.12,1.467-.36.266-.392.169-1.055.248-1.51.092-.529.223-1.05.356-1.571a15.508,15.508,0,0,1,1.743-4.214,13.4,13.4,0,0,1,6.453-5.558,21.676,21.676,0,0,1,9.8-1.282A44.75,44.75,0,0,1,86.239,15.6a22.026,22.026,0,0,0,9.786.9c3.334-.487,5.853-2.206,8.664-3.838a16.771,16.771,0,0,1,9.572-2.512,19.016,19.016,0,0,1,8.731,3.15,13.791,13.791,0,0,1,5.63,6.077c1.232,2.84,1.23,5.96-.794,8.47-2.936,3.64-8.386,4.58-12.92,4.487A25.811,25.811,0,0,1,107.233,31c-3.3-1.1-6.155-2.978-9.245-4.5a24.787,24.787,0,0,0-4.519-1.72,5.266,5.266,0,0,0-4.405.278,21.1,21.1,0,0,1-4.439,2.418,14.972,14.972,0,0,1-5.006.855c-3.321-.024-6.6-1.009-9.821-1.668-2.63-.538-5.445-1.074-8.127-.557-1.12.216-2.754.66-3.158,1.767,6.8,1.173,9.475,9.178,7.557,15.053-1.035,3.172-7.257,6.88-9.676,5.212-1.533-1.056-.6-2.443,0-3.358,1.153-1.753,1.547-4.005-.586-5.226C53.967,38.5,52.032,41,52,41a23.6,23.6,0,0,1-7.888-5.822c-2.971.167-3.422-1.7-2.713-3.22s5.138-3.443,7.525-3.816Z'
              fill='#191847'
              fillRule='evenodd'
            />
          </g>
          <g transform='translate(218 73) rotate(180)'>
            <circle
              cx='39.553'
              cy='39.553'
              r='39.553'
              transform='translate(0 -79.106)'
              fill='#fff'
            />
            <path
              d='M29.054-29.515c-2.2-4.22-1.943-14.927.906-15.682,1.137-.3,3.558.436,6.021,1.961L34.435-54.473h9.132l-3.144,15.05a10.749,10.749,0,0,1,2.742,7.606c-.571,8.363-11.907,6.523-14.111,2.3Z'
              fill='#86684b'
              fillRule='evenodd'
            />
            <g transform='translate(52.318 -22.82) rotate(180)'>
              <path
                d='M22.669,5.059c-.08-.51.078-.76-1.919-2.2S15.53-.27,12.283.468C8.069,1.425,6.4,4.28,4.771,5.668,3.306,6.917,1.836,7.063.965,8.616S-.369,14.634.388,16.41C1.433,18.86,3.493,19.52,5,20.225a8.311,8.311,0,0,0,6.313-.28c.683-1.638,4.36-6.258,6.572-8.2,1.627-1.427,5.161-4.293,4.786-6.687Z'
                fill='#69a1ac'
              />
              <path
                d='M3.043,6.386Q4.729,13.553,11.8,19.93,3.62,14.86,1.522,6.847l1.521-.46Zm1.7-1.7a25.755,25.755,0,0,0,5.394,12.2A19.3,19.3,0,0,1,3.424,5.545Z'
                fill='rgba(0,0,0,0.2)'
                fillRule='evenodd'
              />
            </g>
            <path
              d='M72.224-72.42l-6.5-2.126Q79.7-94.475,81.4-96.68l.075-.1.087-.106c1.517-1.79.765-5.5.557-7.433,2.84-.3,1.243,3.678,4.843,3.247.448-.053.89-.131,1.326-.217l.433-.089.215-.045.428-.09c2.34-.5,4.5-.863,6.475,1.442.324.378.463,1.452-.85,1.834-3.269.952-8.164,2.883-9.028,3.76Q84.2-92.681,72.224-72.42ZM18.1-83.708,7.263-94.16A32.975,32.975,0,0,1-.484-98.568c-.453-.54-.947-1.575,1.183-1.11l.457.1.457.094c1.823.363,3.533.568,3.875-.018.4-.684-.78-1.734-.041-2.63q.738-.9,4.979,5.7L21.69-89.858,18.1-83.708Z'
              fill='#86684b'
              fillRule='evenodd'
            />
            <path
              d='M35.55-49.57A95.954,95.954,0,0,0,15.428-85.078a13.209,13.209,0,0,1-4.948-1.457,1.7,1.7,0,0,1-.861-.981,1.7,1.7,0,0,1,.091-1.3,1.707,1.707,0,0,1,1.2-.9c-.31-.3-.626-.6-.944-.893l3.91-5.72c11.93,8.12,23.307,14.577,27.33,25.78.883,2.455.21,8.341-.483,13.342l-.13.926-.37,2.59-.11.78q-.162,1.135-.283,2.276L35.55-49.569Z'
              fill='#89c5cc'
              fillRule='evenodd'
            />
            <path
              d='M59.7-88.865q-17.578-4.447-28.1-3.949c-.869.041-1.339,3.655-1.085,4.695,3.958,16.2,6.4,28,4.9,38.661q1.8-.632,5.926.567c9.081-9.924,14.317-22.04,18.356-39.974Z'
              fill='#2d3b5f'
              fillRule='evenodd'
            />
            <path
              d='M39.953-48.171c3.6-16.393-8.825-55.03,1.6-85.073,11.584-.9,26.539,9.07,43.019,5.1-2.33,12.362-16.025,25.345-22.4,37.78a1.707,1.707,0,0,1,.294,1.606l-.342,1.043a1.727,1.727,0,0,1-1.671,1.19q-.31.8-.566,1.627c-1.422,4.57-.76,9.338-1.455,13.833q10.21-6.417,20.119-24.294L85.4-92.276q-.621,1.446-1.266,2.852c.76.575,1.427,2.836.683,3.094a8.5,8.5,0,0,1-2.385.458q-13.947,27.9-38.176,38.306Z'
              fill='#89c5cc'
              fillRule='evenodd'
            />
            <path
              d='M42.747-48.16l2.788-5.663-3.307-5,4.045-3L39.721-77.588l8.473,16-3.987,3.036,2.938,4.424Z'
              fill='rgba(0,0,0,0.1)'
              fillRule='evenodd'
            />
            <path
              d='M42.759-97.386a1.52,1.52,0,0,1-1.3-1.71,1.52,1.52,0,0,1,1.725-1.284,1.52,1.52,0,0,1,1.3,1.71A1.52,1.52,0,0,1,42.759-97.386Zm-.831,8.66a1.52,1.52,0,0,1-1.3-1.71,1.52,1.52,0,0,1,1.724-1.284,1.52,1.52,0,0,1,1.3,1.71A1.52,1.52,0,0,1,41.928-88.726Zm.683,8.873a1.52,1.52,0,0,1-1.3-1.71,1.52,1.52,0,0,1,1.724-1.284,1.52,1.52,0,0,1,1.3,1.71A1.52,1.52,0,0,1,42.611-79.853Z'
              fill='rgba(0,0,0,0.4)'
              fillRule='evenodd'
            />
          </g>
          <g transform='translate(216 348) rotate(180)'>
            <circle
              cx='37.213'
              cy='37.213'
              r='37.213'
              transform='translate(0 -74.915)'
              fill='#fff'
            />
            <g opacity='0.8'>
              <path
                d='M30.14-26.14c-2.486-4.45-2.19-15.737,1.022-16.532,1.281-.318,4.01.46,6.788,2.067L36.207-52.45H46.5L42.957-36.585a10.909,10.909,0,0,1,3.092,8.019c-.644,8.816-13.424,6.876-15.91,2.427Z'
                fill='#b28b67'
                fillRule='evenodd'
              />
              <path
                d='M37.923-21.553c-5.521,0-7.614-2.186-7.722-4.12,2.366-.625,6.5-.865,12.44-.824v-.412l-5.147-.412-.858-2.472c1.365.1,2.34-.8,3.119-2.006a2.114,2.114,0,0,0,1.17.358A2.165,2.165,0,0,0,43.07-33.5a2.429,2.429,0,0,0-1.3-2.285c.409-.766.819-1.321,1.3-1.422,1.853.18,4.289,4.6,4.289,8.24,0,3.976-3.3,7.415-9.437,7.415Z'
                fill='#191847'
                fillRule='evenodd'
              />
              <path
                d='M71.412-82.793,62.7-84.053q11.748-25,12.616-26.513c1.3-2.264-.227-6.205-.825-8.29,3.025-.878,2.057,3.808,5.881,2.638,3.49-1.068,6.372-3.487,9.827-.614.425.354.782,1.51-.569,2.184-3.366,1.679-8.308,4.752-9.076,5.884Q78.982-106.45,71.412-82.793Zm-52.678-5.74L8.543-101.756a36.152,36.152,0,0,1-7.767-6.06c-.41-.667-.786-1.887,1.465-1.048s4.658,1.62,5.2.923-.58-2.039.366-2.917q.946-.879,4.542,7.072l11.266,8.993Z'
                fill='#b28b67'
                fillRule='evenodd'
              />
              <path
                d='M41.484-49.589l-3.694.114Q31.755-74.123,11.224-96.46l13.7-12.6c12.928,20.28,19.84,40.3,16.564,59.474Z'
                fill='#89c5cc'
                fillRule='evenodd'
              />
              <path
                d='M63.6-92.842,31.478-97.356q7.336,28.79,4.663,47.815l9.485,2.81c9.34-11.213,13.616-26.033,17.971-46.11Z'
                fill='#dde3e9'
                fillRule='evenodd'
              />
              <path
                d='M45.195-46.792q-1.129-.2-3.438-.628l-1.4-7.039q1.353-12.324,2.613-50.073l21.054,2.96q.279-2.58.456-5.224l15.655,2.2q-9.1,39.253-34.3,57.894l-.007-.008-.005.006Z'
                fill='#89c5cc'
                fillRule='evenodd'
              />
              <path
                d='M58.158-102.565l5.886.827a111.75,111.75,0,0,1-4.487,21.88Q60.907-96,58.158-102.565Z'
                fill='rgba(0,0,0,0.1)'
                fillRule='evenodd'
              />
              <path
                d='M57.54-89.427,46.478-86.045l.68-4.84Z'
                fill='rgba(255,255,255,0.2)'
                fillRule='evenodd'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Feature2Sketch;
