import React from 'react';

function HeroSketch({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='488'
      height='488.001'
      viewBox='0 0 488 488.001'
    >
      <defs>
        <filter
          id='a'
          x='172.588'
          y='123.309'
          width='188.481'
          height='312.131'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='6' result='b' />
          <feFlood floodOpacity='0.239' />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='c'>
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='8.5' result='d' />
          <feFlood floodColor='#fff' floodOpacity='0.541' result='e' />
          <feComposite operator='out' in='SourceGraphic' in2='d' />
          <feComposite operator='in' in='e' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(6172 21958)'>
        <circle
          cx='244'
          cy='244'
          r='244'
          transform='translate(-6172 -21958)'
          fill='#f5f7fa'
        />
        <g transform='translate(789.26 822.692)'>
          <path
            d='M164.965,526.456q29.358-49.5,32.816-62.217c3.458-12.711-11.046-23.158-21.931-51.011s-10.213-58.579-5.106-68.78,24.707-40.726,20.792-79.249,12.528-25.816,16.7-20.9,10.742,22.207,10.742,47.412-10.742,47.5-10.742,52.732q0,5.226,14.324,11.8l104.406,93.476q-4.5,14.292-18.2,22.986c-13.7,8.694-21.3,28.034-24.117,36.05q-2.817,8.017-5.555,17.694H164.965Z'
            transform='translate(-6988 -22819.146)'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M314.487,425.687q-5.5,12.1-10.382,16.857c-2.292,2.234-10.77-12.943-41.3-14.677s-4.571-36.791-7.387-28.775q-2.817,8.017,59.065,26.6Z'
            transform='translate(-6975.524 -22795.109)'
            fillRule='evenodd'
            opacity='0.077'
          />
          <path
            d='M196.038,238.463q4.995,1.669-7.9,26.758C185.785,242.09,190.8,236.713,196.038,238.463Z'
            transform='translate(-6984.6 -22819.17)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M336.3,256.95q18.814-15.7,27.669-28.345c8.854-12.645,15.089-27.425,0-30q-15.09-2.579-30.251,17.436L336.3,256.95Zm0,12.321q17.547-2.111,20.807,1.889T336.3,288.73Zm-2.583,48.513q20.992-2.173,22.385,1.414T333.721,343.63V317.784Zm.826,37.362q16.916,1.653,19.689,4.388t-17.106,17.451Z'
            transform='translate(-6962.583 -22825.172)'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <g transform='translate(-6776.672 -22645.383)'>
            <g
              transform='matrix(1, 0, 0, 1, -184.59, -135.31)'
              filter='url(#a)'
            >
              <rect
                width='152.481'
                height='276.131'
                rx='8'
                transform='translate(184.59 135.31)'
              />
            </g>
            <rect
              width='152.481'
              height='276.131'
              rx='8'
              transform='translate(0 0)'
              fill='#e9f7fd'
            />
            <g
              transform='matrix(1, 0, 0, 1, -184.59, -135.31)'
              filter='url(#c)'
            >
              <rect
                width='152.481'
                height='276.131'
                rx='8'
                transform='translate(184.59 135.31)'
                fill='#fff'
              />
            </g>
          </g>
          <path
            d='M214.464,182.212h134.07a9.2,9.2,0,0,1,9.2,9.2v6.193H205.26v-6.193a9.2,9.2,0,0,1,9.2-9.2Z'
            transform='translate(-6981.931 -22827.596)'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <path
            d='M31.215,52.231c4.742-8.834,4.178-31.239-1.95-32.819-2.444-.629-7.652.912-12.95,4.1L19.64,0H0L6.761,31.495C2.99,35.918.438,41.338.865,47.412,1,49.321,2.485,50.475,3.115,51.9c5.141,11.617,23.875,8.2,28.1.334Z'
            transform='translate(-6695.276 -22640.629) rotate(180)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M20.658,42.2c5.511-.472,10.431-2.152,11.826-3.149,0-5.213-1.01-6.761-9.646-6.624-2.122-1.12-3.752-3.049-5.452-5.577q1.129-15.7,5.967-15.7c4.838,0,8.944,3.07,11.067,3.07,1.548,0,1.2-1.935.176-3.37-.846-1.184-4.967-2.324-4.967-4.161,0-.949.871-1.027,1.712-1.033h.495c.581-.006,1.049-.058,1.049-.472,0-1.8.572-5.186-2.554-5.186-3.165,0-12.24,1.153-13.8,5.847q-1.5,4.544-1.947,18.831a4.909,4.909,0,0,1-4.016-8.38Q8.746,13.715,6.7,11.293C1.223,14.581-2.846,24.2,2.514,33.443c1.482,4.528,4.835,6.946,8.8,8.068a14.254,14.254,0,0,0,9.349.689Z'
            transform='translate(-6695.186 -22659.258) rotate(180)'
            fillRule='evenodd'
          />
          <path
            d='M6.752,38.134l24.7-20.929a70.286,70.286,0,0,0,17.2-8.345c1.048-1.1,2.256-3.256-2.36-2.575s-9.5,1.19-10.255-.348,1.925-3.626.481-5.67Q35.079-1.778,25.039,11.829L0,24.316,6.752,38.135Z'
            transform='translate(-6751.014 -22548.02) rotate(180)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M0,88.893l8.688,3.739C23.476,63.4,44.142,35.663,76.55,13.547L70.2,0C42.252,13.321,16.028,23.273,3.765,45.858-.351,53.438.545,79.446,0,88.893Z'
            transform='translate(-6698.734 -22565.707) rotate(175)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M0,2.045q38.592-4.09,60.751,0c1.829.337,2.118,3.4,1.27,5.478C48.8,39.89,39.739,68.131,39.739,90.612Q36.1,88.779,27,90.062C10.709,66.9,3.218,40.314,0,2.045Z'
            transform='translate(-6675.637 -22566.617) rotate(180)'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <path
            d='M0,0H83.313c-15.4,41.17-20.385,167.029-21.966,176.324H53.313C36.652,137.866,2.849,59.49,0,0Z'
            transform='translate(-6644.036 -22482.297) rotate(180)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M37.159,87.211Q26.11,82.907,21.3,76.565C2.422,51.666-.283,25.195.022,4,.3-5.117,31.172,3.567,45.714,8.238l-.489,12.488C25.88,15.41,17.158,15.173,21.5,22.546q9.6,16.308,15.08,38.936l.58,25.729Z'
            transform='translate(-6649.681 -22577.855) rotate(172)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M56.935,50.345h-42.5L0,0H40.857Z'
            transform='translate(-6693.85 -22563.348) rotate(180)'
            fill='#5c9640'
            fillRule='evenodd'
          />
          <path
            d='M258.831,226.94c-1.743,1.688,1.077,9.1,6.119,12.911q5.041,3.813,16.663,1.844-1.531-9.159-3.158-11.221T271,226.94l1.492,4.271q-4.413,0-6.481-1.517C263.946,228.182,260.573,225.254,258.831,226.94Z'
            transform='translate(-6973.94 -22820.936)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M271.742,176.09l2.236,11.8h-5.246l7.882,13.17-9,13.767,6.365-13.767L266.484,186.4l6.239.66Z'
            transform='translate(-6972.709 -22828.518)'
            fill='rgba(62,160,221,0.15)'
            fillRule='evenodd'
          />
          <path
            d='M214.563,332.049H324.2a4.6,4.6,0,0,1,4.6,4.6v20.236a4.6,4.6,0,0,1-4.6,4.6H244.509l-4.98,4.265a1.151,1.151,0,0,1-1.5,0l-4.981-4.265H218.013a4.6,4.6,0,0,1-4.6-4.6V333.2a1.151,1.151,0,0,1,1.151-1.151Z'
            transform='translate(-6980.703 -22805.027)'
            fillRule='evenodd'
            opacity='0.078'
          />
          <path
            d='M217.3,330.627H323.485a4.6,4.6,0,0,1,4.6,4.6v20.236a4.6,4.6,0,0,1-4.6,4.6H245.5a4.6,4.6,0,0,0-2.994,1.108l-3.688,3.158a1.151,1.151,0,0,1-1.5,0l-3.688-3.158a4.6,4.6,0,0,0-2.994-1.108H217.3a4.6,4.6,0,0,1-4.6-4.6V335.229A4.6,4.6,0,0,1,217.3,330.627Z'
            transform='translate(-6980.811 -22805.242)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M189.565,366.876H328.48a4.6,4.6,0,0,1,4.6,4.6v20.236a4.6,4.6,0,0,1-4.6,4.6H315.963a4.6,4.6,0,0,0-2.994,1.107l-3.688,3.158a1.151,1.151,0,0,1-1.5,0l-3.689-3.158a4.6,4.6,0,0,0-2.994-1.107H189.566a4.6,4.6,0,0,1-4.6-4.6V371.478a4.6,4.6,0,0,1,4.6-4.6Z'
            transform='translate(-6984.988 -22799.781)'
            fillRule='evenodd'
            opacity='0.078'
          />
          <path
            d='M188.854,365.454H327.769a4.6,4.6,0,0,1,4.6,4.6v20.236a4.6,4.6,0,0,1-4.6,4.6H315.252A4.6,4.6,0,0,0,312.258,396l-3.688,3.158a1.151,1.151,0,0,1-1.5,0L303.386,396a4.6,4.6,0,0,0-2.994-1.108H188.855a4.6,4.6,0,0,1-4.6-4.6V370.056a4.6,4.6,0,0,1,4.6-4.6Z'
            transform='translate(-6985.095 -22799.996)'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M327.462,306.07c-2.229-13.566,9.563-35.648,17.892-43.028s15.944,1.274,17.242,4.574,1.231,17.908-10.609,31.45-12.475-3.829-14.889-2.606-7.408,23.177-9.636,9.611Z'
            transform='translate(-6963.567 -22815.857)'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M327.219,308.655a19.863,19.863,0,0,1,5.337-17.274c2.114-2.057,9.83-4.382,12.645,0s2.727,7.792-2.964,14.768S328.592,317.977,327.219,308.655Z'
            transform='translate(-6963.6 -22811.527)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M.246,41.169C-1.745,29.007,8.788,9.212,16.228,2.6s14.239,1.141,15.4,4.1,1.1,16.052-9.475,28.192-11.141-3.433-13.3-2.337S2.238,53.329.247,41.169Z'
            transform='translate(-6631.223 -22510.943) rotate(9)'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M.227,17.567A17.482,17.482,0,0,1,5.051,2.212c1.91-1.829,8.885-3.895,11.431,0S18.944,9.139,13.8,15.341,1.468,25.854.227,17.569Z'
            transform='translate(-6636.39 -22482.922) rotate(9)'
            fill='#feb183'
            fillRule='evenodd'
          />
          <path
            d='M.189,31.438c-1.53-9.287,6.57-24.4,12.293-29.455s10.954.871,11.846,3.131.846,12.259-7.289,21.528S8.469,24.021,6.81,24.859,1.72,40.725.189,31.438Z'
            transform='matrix(0.94, 0.342, -0.342, 0.94, -6625.847, -22471.695)'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M.166,14.315A15.269,15.269,0,0,1,3.673,1.8c1.389-1.49,6.462-3.173,8.313,0s1.793,5.644-1.949,10.7S1.068,21.066.164,14.315Z'
            transform='matrix(0.94, 0.342, -0.342, 0.94, -6633.923, -22451.359)'
            fill='#feb183'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  );
}

export default HeroSketch;
