import React from 'react';

function Feature1Sketch({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='492'
      height='492'
      viewBox='0 0 492 492'
    >
      <defs>
        <filter
          id='a'
          x='248.913'
          y='85.158'
          width='240.182'
          height='383.682'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='6' result='b' />
          <feFlood floodOpacity='0.239' />
          <feComposite operator='in' in2='b' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='c'>
          <feOffset dx='6' dy='6' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='8.5' result='d' />
          <feFlood floodColor='#fff' floodOpacity='0.541' result='e' />
          <feComposite operator='out' in='SourceGraphic' in2='d' />
          <feComposite operator='in' in='e' />
          <feComposite operator='in' in2='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(0 -10)'>
        <path d='M0,10H492V502H0Z' fill='none' fillRule='evenodd' />
        <g transform='translate(260.913 107.158)'>
          <g transform='matrix(1, 0, 0, 1, -260.91, -97.16)' filter='url(#a)'>
            <rect
              width='204.182'
              height='347.682'
              rx='8'
              transform='translate(260.91 97.16)'
            />
          </g>
          <rect width='204.182' height='347.682' rx='8' fill='#e9f7fd' />
          <g transform='matrix(1, 0, 0, 1, -260.91, -97.16)' filter='url(#c)'>
            <rect
              width='204.182'
              height='347.682'
              rx='8'
              transform='translate(260.91 97.16)'
              fill='#fff'
            />
          </g>
        </g>
        <path
          d='M8,0H196.182a8,8,0,0,1,8,8V21.45H0V8A8,8,0,0,1,8,0Z'
          transform='translate(260.913 107.158)'
          fill='#2d3b5f'
          fillRule='evenodd'
        />
        <g transform='translate(255.424 58)'>
          <path
            d='M73.123,8.972c-4.633,8.7-4.083,30.78,1.9,32.335,2.388.62,7.475-.9,12.653-4.044l-3.25,23.17H103.62L97.014,29.4c3.684-4.358,6.178-9.7,5.76-15.684-.13-1.881-1.582-3.018-2.2-4.42C95.554-2.146,77.251,1.217,73.123,8.972Z'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M83.526.5C78.142.965,73.334,2.62,71.972,3.6c0,5.137.988,6.66,9.423,6.526,2.073,1.1,3.667,3,5.326,5.494q-1.1,15.466-5.828,15.468c-4.728,0-8.739-3.025-10.813-3.025-1.512,0-1.175,1.907-.172,3.321.827,1.167,4.854,2.291,4.854,4.1,0,.933-.848,1.012-1.668,1.018h-.453c-.584,0-1.061.049-1.061.464,0,1.774-.56,5.11,2.5,5.11,3.092,0,11.958-1.136,13.478-5.761q1.47-4.478,1.9-18.554a4.316,4.316,0,0,1,.572-.037,4.843,4.843,0,0,1,3.352,8.294q1.785,2.544,3.78,4.93c5.355-3.238,9.328-12.717,4.093-21.823a11.884,11.884,0,0,0-8.593-7.95A13.818,13.818,0,0,0,83.526.5Z'
            fillRule='evenodd'
          />
          <path
            d='M42.451,114.2,18.38,134.785a68.267,68.267,0,0,0-16.765,8.208c-1.021,1.08-2.2,3.2,2.3,2.533s9.262-1.17,9.994.341-1.876,3.567-.469,5.577q1.407,2.01,11.192-11.37l24.4-12.282-6.58-13.59Z'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M92.424,47.222,83.672,44.3C71.8,74.215,54.093,103.156,24.51,127.6l7.316,12.73c26-15.447,50.61-27.446,60.6-50.624,3.351-7.78.273-33.183,0-42.485Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M122.484,131.4q-37.606,4.021-59.2,0c-1.784-.332-2.066-3.344-1.238-5.388,12.88-31.831,21.713-59.6,21.713-81.715q3.544,1.8,12.409.54C112.048,67.627,119.348,93.769,122.484,131.4Z'
            fill='#2d3b5f'
            fillRule='evenodd'
          />
          <path
            d='M153.277,216.343H72.092c15-40.49,19.864-164.27,21.4-173.41h7.829c16.234,37.822,49.175,114.9,51.951,173.41Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M100.092,42.516q11.247,2.679,16.746,8.2c21.6,21.665,27.8,47.075,30.377,67.762.964,8.915-30,4.683-44.668,2.123l-1.221-12.229c19.39,2.53,27.837,1.567,22.649-5.02Q112.5,88.783,104.142,67.494l-4.05-24.978Z'
            fill='#fff'
            fillRule='evenodd'
          />
          <path
            d='M49.254,87.12H90.668l14.068,49.513H64.922Z'
            fill='#5c9640'
            fillRule='evenodd'
          />
          <path
            d='M83.877,106.548c-1.695,1.627,1.049,8.77,5.955,12.446q4.9,3.675,16.215,1.778-1.489-8.831-3.074-10.818t-7.25-3.406l1.453,4.117q-4.294,0-6.307-1.462c-2.013-1.46-5.3-4.283-6.992-2.655Z'
            fill='#ffc9a9'
            fillRule='evenodd'
          />
          <path
            d='M97.817,49.006l2.1,11.584H94.993l7.4,12.931-8.449,13.52,5.975-13.52L92.882,59.132l5.855.648Z'
            fill='rgba(62,160,221,0.15)'
            fillRule='evenodd'
          />
        </g>
        <path
          d='M271.857,304.492H442.352a4,4,0,0,1,4,4v38.09a4,4,0,0,1-4,4h-124.9a4,4,0,0,0-2.62.978l-7.079,6.137a1,1,0,0,1-1.31,0l-7.078-6.137a4,4,0,0,0-2.62-.977h-24.89a4,4,0,0,1-4-4V308.492A4,4,0,0,1,271.857,304.492Zm-50.464,62.171H442.46a4,4,0,0,1,4,4v38.091a4,4,0,0,1-4,4H418.845a4,4,0,0,0-2.62.978l-7.076,6.136a1,1,0,0,1-1.31,0l-7.077-6.136a4,4,0,0,0-2.62-.978H221.393a4,4,0,0,1-4-4v-38.09a4,4,0,0,1,4-4Z'
          fill='#5c9640'
          fillRule='evenodd'
        />
        <path
          d='M148.417,177.524c4.435,8.2,3.908,28.985-1.823,30.45-2.286.583-7.154-.847-12.11-3.809l3.11,21.819H119.23l6.322-29.22c-3.526-4.1-5.912-9.134-5.514-14.77,1.15-16.237,23.946-12.665,28.38-4.47Z'
          fill='#b28b67'
          fillRule='evenodd'
        />
        <g transform='translate(101.628 164.524)'>
          <path
            d='M45.59,9.823c-.161-.99.157-1.477-3.858-4.266S31.232-.523,24.7.908C16.229,2.767,12.863,8.309,9.595,11c-2.946,2.43-5.9,2.713-7.653,5.727S-.742,28.417.782,31.865c2.1,4.755,6.244,6.036,9.268,7.406,5.188,2.35,12.52-.132,12.7-.543,1.374-3.18,8.77-12.15,13.218-15.92,3.271-2.771,10.379-8.337,9.624-12.985Z'
            fill='#89c5cc'
          />
          <path
            d='M6.12,12.4q3.39,13.916,17.6,26.3Q7.279,28.856,3.061,13.294l3.06-.894ZM9.533,9.1A49.164,49.164,0,0,0,20.381,32.8Q10.14,24.918,6.887,10.764Z'
            fill='rgba(0,0,0,0.2)'
            fillRule='evenodd'
          />
        </g>
        <path
          d='M118,289.144l37.3,94.6,25.019,79.976h9.88L151.956,289.144Z'
          fill='#b28b67'
          fillRule='evenodd'
        />
        <path
          d='M110.493,289.144c-.4,33.243-6.19,78-7.116,80.072q-.925,2.072-63.9,52.7l6.8,7.3q74.3-43.41,77.742-49.2c3.439-5.79,14.633-59.068,23.62-90.87H110.493Z'
          fill='#b28b67'
          fillRule='evenodd'
        />
        <path
          d='M38.067,419.7a15.1,15.1,0,0,0-4.225,2.122,38.359,38.359,0,0,0-4.165,4.389l27.554,32.837q5.313-2.279,3.338-5.32-1.649-2.526-3.234-5.093l-5.921-20.745a1,1,0,0,0-1.265-.678l-2.972.945a13.643,13.643,0,0,1-4.675-3.148,11.383,11.383,0,0,1-1.823-3.994,2,2,0,0,0-2.612-1.315ZM176.1,460.532a15.079,15.079,0,0,0-1.09,4.6,38.321,38.321,0,0,0,.684,6.012h42.866q1.671-5.535-1.93-5.976t-5.98-.8l-19.7-8.8a1,1,0,0,0-1.333.532l-1.187,2.885a13.655,13.655,0,0,1-5.416,1.558,11.368,11.368,0,0,1-4.231-1.171,2,2,0,0,0-2.686,1.155Z'
          fill='#e4e4e4'
          fillRule='evenodd'
        />
        <path
          d='M143.084,455.482l46.972-5.108-34.812-161.23h-42.07Z'
          fill='#69a1ac'
          fillRule='evenodd'
        />
        <path
          d='M49.194,410.148,80,446.689q48.678-53.27,56.76-69.518t14.528-88.027H103.928q-3.522,63.15-4.748,66.087T49.194,410.148Z'
          fill='#89c5cc'
          fillRule='evenodd'
        />
        <path
          d='M212.013,240.792l-.033.392c-.145,1.84-.136,3.784,1.566,3.43a26,26,0,0,0,5.65-2.666l.562-.315c2.9-1.626,5.7-3.167,6.46-2.491,2.042,1.806.221,7.35-1.924,10.108-4.01,5.155-7.33,6.918-15.534,9.227a44.957,44.957,0,0,0-13.915,7.258l-.658-11.7,11.55-6.4q2.43-9.333,5.81-9.392c.77-.36.6.991.466,2.545Zm-18.345-22.826-.432.78-.284.52c-1.832,3.39-3.343,6.721-2.385,7.758,1.08,1.17,3.774-.464,5.063,1.48q1.29,1.944-13.807,6.142l-19.1,17.65-9.71-10.515,26.292-14.53a65.2,65.2,0,0,1,12.924-12.206c1.3-.547,3.564-.877,1.443,2.92Z'
          fill='#997659'
          fillRule='evenodd'
        />
        <path
          d='M140.205,238.544A64.168,64.168,0,0,0,174.8,228.091l5.146,11.58c-9.038,9.05-21.747,16.486-32.86,16.171A10.143,10.143,0,0,1,140.205,238.544Z'
          fill='#69a1ac'
          fillRule='evenodd'
        />
        <path
          d='M201.79,248.41q8.561,17.874,7.915,25.485c-19.744,10.407-36.666,15.046-50.607,15.351.449,8.344-.558,16.287-3.94,23.544-6.953,14.919-47.667,1.1-61.356,3.822-8.573-28.092,8-39.509,8.016-57.484.014-19.685,15.679-49.984,17.983-49.984h21.018c.9,11.656,4.61,24.235,8.465,36.928l.661,2.177.662,2.178.658,2.18q.68,2.253,1.34,4.507,14.664,3.69,49.184-8.7Z'
          fill='#82bcc3'
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
}

export default Feature1Sketch;
