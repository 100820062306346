import React from 'react';
import ClientsIcon from '../icons/clientsIcon';
import {
  clientsSection,
  clientsBox,
  headingWrap,
  clientsIcon,
  clientHeading,
  blueBox,
  latteBox,
  gradientBox,
  canaryBox,
  aliceblueBox,
  firstClientCol,
  thirdClientCol,
} from './client.module.scss';

function Clients() {
  return (
    <section className={clientsSection}>
      <div className={clientsBox}>
        <div className={headingWrap}>
          <ClientsIcon className={clientsIcon} />
          <div className={clientHeading}>
            <h2>Our Clients</h2>
            <p className='medium-font-heavy'>
              Few of our happy clients who are using Daily Doc app
            </p>
          </div>
        </div>
        <div className='display-flex'>
          <div className={firstClientCol}>
            <div className={latteBox}></div>
            <div className={`flex-column-center textalign-center ${blueBox}`}>
              Morya Hospital, Alibaug
            </div>
          </div>
          <div>
            <div className={`flex-column-center textalign-center ${blueBox}`}>
              Patil Hospital PVT LTD, Satara
            </div>
            <div className={gradientBox}></div>
          </div>
          <div className={thirdClientCol}>
            <div className={canaryBox}></div>
            <div
              className={`flex-column-center textalign-center ${aliceblueBox}`}
            >
              7 Oranges Hospital, Pune
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
