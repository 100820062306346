import React from 'react';
import { Link } from 'gatsby';
import HeroSketch from '../icons/heroSketch';
import {
  heroSection,
  heroSketch,
  heroTextWrap,
  heroText,
  freeFor6month,
} from './hero.module.scss';

function Hero() {
  return (
    <section className={heroSection}>
      <div className='flex-space'>
        <div className={heroTextWrap}>
          <h1>Hospital Communication Simplified.</h1>
          <p className={`medium-font-medium ${heroText}`}>
            A unique platform for your hospital to streamline communication and
            bring work schedule online.
          </p>
          <Link to='/pricing' className='button button-green'>
            TRY FOR FREE
          </Link>
          <p className={freeFor6month}>Get Daily Doc Free for 6 Months</p>
        </div>
        <div>
          <HeroSketch className={heroSketch} />
        </div>
      </div>
    </section>
  );
}

export default Hero;
