// Step 1: Import React
import * as React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero/hero';
import Giff1 from '../components/giffs/giff1';
import Giff2 from '../components/giffs/giff2';
import Giff3 from '../components/giffs/giff3';
import Giff4 from '../components/giffs/giff4';
import DoesItAll from '../components/does-it-all/doesItAll';
import GetFree from '../components/get-free/getFree';
import Feature1 from '../components/feature/feature1';
import Feature2 from '../components/feature/feature2';
import Designedby from '../components/designedby/designedby';
import Clients from '../components/clients/clients';
import GetStarted from '../components/get-started/getStarted';
import Testimony from '../components/testimony/testimony';

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout pageTitle='Home Page'>
      <Hero />
      <DoesItAll />
      <Giff1 />
      <Giff2 />
      <Giff3 />
      <Giff4 />
      <Clients />
      <Feature1 />
      <Feature2 />
      <GetStarted />
      <Testimony />
      <Designedby />
      <GetFree />
    </Layout>
  );
};

// Step 3: Export your component
export default IndexPage;
