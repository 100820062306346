import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CheckIcon from '../icons/checkIcon';

import {
  giffSection,
  evenGiffBox,
  giffSketch,
  giffWrap,
  evenHeadingWrap,
  checkIcon,
} from './giff.module.scss';

function Giff4() {
  return (
    <section className={`bg-indigo ${giffSection}`}>
      <div className={evenGiffBox}>
        <div className={giffWrap}>
          <StaticImage
            className={giffSketch}
            srcSet='../../images/live-care-team@3x.png 3x, ../../live-care-team@2x.png 2x, ../../live-care-team@1x.png 1x'
            src='../../images/live-care-team.png'
            alt='Live care team image'
          />
        </div>
        <div className={evenHeadingWrap}>
          <CheckIcon className={checkIcon} />
          <h2>Live Care Team</h2>
          <p className='medium-font-heavy'>
            Find out who is taking care of a patient right away. Doctors,
            nurses, PT, OT, Pharmacist and any other care team member can be
            seen on one click. Send them a message right there.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Giff4;
