import React from 'react';
import { Link } from 'gatsby';
import {
  designedbySection,
  designedbyBox,
  designedbyItem,
  gotQuestions,
} from './designedby.module.scss';
function Designedby() {
  return (
    <section className={designedbySection}>
      <h2>Designed for Hospitals by Doctors</h2>
      <div className={designedbyBox}>
        <div className={designedbyItem}>
          <h4>Under a minute onboarding</h4>
          <p className='lead-font-medium'>
            Easy sing up, quick set up of the hospital and employee onboarding
            under a minute.
          </p>
          <Link className='link-font' to='/'>
            <p>Read More</p>
          </Link>
        </div>
        <div className={designedbyItem}>
          <h4>Easy set up of work schedule</h4>
          <p className='lead-font-medium'>
            One time set up of nursing shifts and all other department shifts.
            Easy schedule assignment.
          </p>
          <Link className='link-font' to='/'>
            <p>Read More</p>
          </Link>
        </div>
      </div>
      <div className={gotQuestions}>
        <p className='medium-font-heavy'>Got any questions or suggestions?</p>
        <div>
          <Link to='/contact' className='button button-green'>
            CONTACT US
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Designedby;
