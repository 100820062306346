import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CheckIcon from '../icons/checkIcon';
import {
  giffSection,
  giffBox,
  giffSketch,
  giffWrap,
  headingWrap,
  checkIcon,
} from './giff.module.scss';

function Giff1() {
  return (
    <section className={`bg-indigo ${giffSection}`}>
      <div className={giffBox}>
        <div className={headingWrap}>
          <CheckIcon className={checkIcon} />
          <h2>Secure Messaging</h2>
          <p className='medium-font-heavy'>
            Send and receive messages from doctors, RMOs, nurses and other
            health care providers. Avoid too many interruptions and call
            fatigue.
          </p>
        </div>
        <div className={giffWrap}>
          <StaticImage
            className={giffSketch}
            srcSet='../../images/secure-communication@3x.png 3x, ../../secure-communication@2x.png 2x, ../../secure-communication@1x.png 1x'
            src='../../images/secure-communication.png'
            alt='secure messages'
          />
        </div>
      </div>
    </section>
  );
}

export default Giff1;
