import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CheckIcon from '../icons/checkIcon';
import {
  giffSection,
  giffBox,
  giffSketch,
  giffWrap,
  headingWrap,
  checkIcon,
} from './giff.module.scss';

function Giff3() {
  return (
    <section className={`bg-indigo ${giffSection}`}>
      <div className={giffBox}>
        <div className={headingWrap}>
          <CheckIcon className={checkIcon} />
          <h2>In-app Scheduling</h2>
          <p className='medium-font-heavy'>
            Create work schedule for all departments in the hospital. It will be
            available to see in the app. Changes made to the schedule reflect
            instatnly.
          </p>
        </div>
        <div className={giffWrap}>
          <StaticImage
            className={giffSketch}
            srcSet='../../images/in-app-scheduling@3x.png 3x, ../../in-app-scheduling@2x.png 2x, ../../in-app-scheduling@1x.png 1x'
            src='../../images/in-app-scheduling@1x.png'
            alt='in app scheduling image'
          />
        </div>
      </div>
    </section>
  );
}

export default Giff3;
