import React from 'react';
import { Link } from 'gatsby';
import {
  featureSection,
  featuresBox,
  featureContent,
  feature1Sketch,
  featureFont,
} from './feature.module.scss';
import Feature1Sketch from '../icons/feature1Sketch';

function Feature1() {
  return (
    <section className={`bg-lightblue ${featureSection}`}>
      <h2 className='textalign-center'>Many useful features</h2>
      <div className={featuresBox}>
        <div className={featureContent}>
          <h2>Share files and images</h2>
          <ul className={featureFont}>
            <li>Share files, images, reports and more.</li>
            <li>Never miss a message. No dropped messages.</li>
            <li>Color coded priority levels.</li>
            <li>Find patients care team on one click.</li>
          </ul>
          <Link to='/' className='link-font'>
            LEARN MORE
          </Link>
        </div>
        <div>
          <Feature1Sketch className={feature1Sketch} />
        </div>
      </div>
    </section>
  );
}

export default Feature1;
