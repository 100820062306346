import React from 'react';
import { Link } from 'gatsby';
import {
  featureSection,
  featureContent,
  feature2Sketch,
  featuresBox,
  featureFont,
} from './feature.module.scss';
import Feature2Sketch from '../icons/feature2Sketch';

function Feature2() {
  return (
    <section className={featureSection}>
      <div className='large-container'>
        <div className={featuresBox}>
          <div className={featureContent}>
            <h2>Manage Tasks</h2>
            <ul className={featureFont}>
              <li>Create Task list and track it.</li>
              <li>Make patients care plan for the day.</li>
              <li>Create hand over report for everyone to see.</li>
              <li>Voice record reports and memos</li>
              <li>Take notes while roudning,</li>
              <li>Send discharge notification to care team.</li>
            </ul>
            <Link to='/' className='link-font'>
              LEARN MORE
            </Link>
          </div>
          <div>
            <Feature2Sketch className={feature2Sketch} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feature2;
