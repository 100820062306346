import React from 'react';

function ClientsIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='144'
      height='187.001'
      viewBox='0 0 144 187.001'
    >
      <defs>
        <linearGradient
          id='a'
          x1='0.5'
          y1='0.188'
          x2='0.5'
          y2='0.976'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#71cfcc' />
          <stop offset='1' stopColor='#479ca7' />
        </linearGradient>
        <filter
          id='b'
          x='32'
          y='0'
          width='80'
          height='80'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='c' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='c' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='e'
          x='60'
          y='7'
          width='75'
          height='74'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='f' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='f' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='h'
          x='9'
          y='7'
          width='74'
          height='74'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='i' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='i' />
          <feComposite in='SourceGraphic' />
        </filter>
        <linearGradient id='j' y1='0.184' y2='1' xlinkHref='#a' />
        <filter
          id='k'
          x='32'
          y='27'
          width='80'
          height='88'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='l' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='l' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='n'
          x='60'
          y='28'
          width='75'
          height='81'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='o' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='o' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='q'
          x='9'
          y='28'
          width='74'
          height='81'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='r' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='r' />
          <feComposite in='SourceGraphic' />
        </filter>
        <linearGradient
          id='s'
          x1='0.782'
          y1='-0.905'
          x2='0.131'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#71cfcc' />
          <stop offset='1' stopColor='#fcfcfc' />
        </linearGradient>
        <filter
          id='t'
          x='23'
          y='89.001'
          width='98'
          height='98'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='u' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='u' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='w'
          x='51'
          y='79.001'
          width='93'
          height='92'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='x' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='x' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='z'
          x='0'
          y='79.001'
          width='92'
          height='92'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='aa' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='aa' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='ac'
          x='23'
          y='54.001'
          width='98'
          height='106'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='ad' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='ad' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='af'
          x='51'
          y='51.001'
          width='93'
          height='99'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='ag' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='ag' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter
          id='ai'
          x='0'
          y='51.001'
          width='92'
          height='99'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dx='3' dy='3' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='9' result='aj' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='aj' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='translate(-227 -5285.001)'>
        <g transform='translate(124 -1.453)'>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#b)'>
            <circle
              cx='13'
              cy='13'
              r='13'
              transform='translate(56 24)'
              fill='#479ca7'
            />
          </g>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#e)'>
            <ellipse
              cx='10.5'
              cy='10'
              rx='10.5'
              ry='10'
              transform='translate(84 31)'
              fill='#479ca7'
            />
          </g>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#h)'>
            <circle
              cx='10'
              cy='10'
              r='10'
              transform='translate(33 31)'
              fill='#479ca7'
            />
          </g>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#k)'>
            <path
              d='M13,0h0A13,13,0,0,1,26,13V30.286A3.714,3.714,0,0,1,22.286,34H3.714A3.714,3.714,0,0,1,0,30.286V13A13,13,0,0,1,13,0Z'
              transform='translate(56 51)'
              fill='#479ca7'
            />
          </g>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#n)'>
            <path
              d='M10.5,0h0A10.5,10.5,0,0,1,21,10.5V24a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V10.5A10.5,10.5,0,0,1,10.5,0Z'
              transform='translate(84 52)'
              fill='#479ca7'
            />
          </g>
          <g transform='matrix(1, 0, 0, 1, 103, 5286.45)' filter='url(#q)'>
            <path
              d='M10,0h0A10,10,0,0,1,20,10V24.143A2.857,2.857,0,0,1,17.143,27H2.857A2.857,2.857,0,0,1,0,24.143V10A10,10,0,0,1,10,0Z'
              transform='translate(33 52)'
              fill='#479ca7'
            />
          </g>
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#t)'>
          <circle
            cx='13'
            cy='13'
            r='13'
            transform='translate(56 122)'
            fill='url(#s)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#w)'>
          <ellipse
            cx='10.5'
            cy='10'
            rx='10.5'
            ry='10'
            transform='translate(84 112)'
            fill='url(#s)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#z)'>
          <circle
            cx='10'
            cy='10'
            r='10'
            transform='translate(33 112)'
            fill='url(#s)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#ac)'>
          <path
            d='M3.714,0H22.286A3.714,3.714,0,0,1,26,3.714V21A13,13,0,0,1,13,34h0A13,13,0,0,1,0,21V3.714A3.714,3.714,0,0,1,3.714,0Z'
            transform='translate(56 87)'
            fill='url(#s)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#af)'>
          <path
            d='M3,0H18a3,3,0,0,1,3,3V16.5A10.5,10.5,0,0,1,10.5,27h0A10.5,10.5,0,0,1,0,16.5V3A3,3,0,0,1,3,0Z'
            transform='translate(84 84)'
            fill='url(#s)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, 227, 5285)' filter='url(#ai)'>
          <path
            d='M2.857,0H17.143A2.857,2.857,0,0,1,20,2.857V17A10,10,0,0,1,10,27h0A10,10,0,0,1,0,17V2.857A2.857,2.857,0,0,1,2.857,0Z'
            transform='translate(33 84)'
            fill='url(#s)'
          />
        </g>
      </g>
    </svg>
  );
}

export default ClientsIcon;
