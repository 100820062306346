import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  getStartedSection,
  image,
  getStartedItem,
  getStartedBox,
} from './getStarted.module.scss';

function GetStarted() {
  return (
    <section className={getStartedSection}>
      <h2>Get Started with Daily Doc</h2>
      <div className={getStartedBox}>
        <div className={getStartedItem}>
          <StaticImage
            className={image}
            src='../../images/free_demo.jpg'
            alt='demo picture'
          />
          <h4>Get a free demo</h4>
          <p>
            See all possible ways Daily Doc can improve the efficiency at your
            hospital
          </p>
        </div>
        <div className={getStartedItem}>
          <StaticImage
            className={image}
            src='../../images/onboard_staff.jpg'
            alt='demo picture'
          />
          <h4>Onboard Staff</h4>
          <p>
            Onboard the whole hospital staff in less than 10 minutes. Easy step
            by step guide.
          </p>
        </div>
        <div className={getStartedItem}>
          <StaticImage
            className={image}
            src='../../images/support.jpg'
            alt='demo picture'
          />
          <h4>Support</h4>
          <p>Great support availabe 24 hrs. Call, email or send a message. </p>
        </div>
      </div>
      <div className='textalign-center'>
        <Link to='/contact' className='button button-green'>
          REQUEST A DEMO
        </Link>
      </div>
    </section>
  );
}

export default GetStarted;
