import React from 'react';

function StarsIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='123.751'
      height='19.372'
      viewBox='0 0 123.751 19.372'
    >
      <g transform='translate(-375.625 -8988)'>
        <g transform='translate(375.625 8988.444)'>
          <path
            d='M19.7,7.644a1.048,1.048,0,0,0-.9-.722l-5.7-.518L10.841,1.128a1.05,1.05,0,0,0-1.931,0L6.656,6.4l-5.7.518a1.051,1.051,0,0,0-.6,1.837l4.309,3.779L3.4,18.135a1.048,1.048,0,0,0,1.561,1.134L9.875,16.33l4.915,2.939a1.05,1.05,0,0,0,1.562-1.134l-1.271-5.6L19.391,8.76A1.05,1.05,0,0,0,19.7,7.644Zm0,0'
            transform='translate(0 -0.491)'
            fill='#ffc107'
          />
        </g>
        <g transform='translate(401.625 8988)'>
          <path
            d='M19.7,7.644a1.048,1.048,0,0,0-.9-.722l-5.7-.518L10.841,1.128a1.05,1.05,0,0,0-1.931,0L6.656,6.4l-5.7.518a1.051,1.051,0,0,0-.6,1.837l4.309,3.779L3.4,18.135a1.048,1.048,0,0,0,1.561,1.134L9.875,16.33l4.915,2.939a1.05,1.05,0,0,0,1.562-1.134l-1.271-5.6L19.391,8.76A1.05,1.05,0,0,0,19.7,7.644Zm0,0'
            transform='translate(0 -0.491)'
            fill='#ffc107'
          />
        </g>
        <g transform='translate(427.625 8988.372)'>
          <path
            d='M19.7,7.644a1.048,1.048,0,0,0-.9-.722l-5.7-.518L10.841,1.128a1.05,1.05,0,0,0-1.931,0L6.656,6.4l-5.7.518a1.051,1.051,0,0,0-.6,1.837l4.309,3.779L3.4,18.135a1.048,1.048,0,0,0,1.561,1.134L9.875,16.33l4.915,2.939a1.05,1.05,0,0,0,1.562-1.134l-1.271-5.6L19.391,8.76A1.05,1.05,0,0,0,19.7,7.644Zm0,0'
            transform='translate(0 -0.491)'
            fill='#ffc107'
          />
        </g>
        <g transform='translate(453.625 8988.372)'>
          <path
            d='M19.7,7.644a1.048,1.048,0,0,0-.9-.722l-5.7-.518L10.841,1.128a1.05,1.05,0,0,0-1.931,0L6.656,6.4l-5.7.518a1.051,1.051,0,0,0-.6,1.837l4.309,3.779L3.4,18.135a1.048,1.048,0,0,0,1.561,1.134L9.875,16.33l4.915,2.939a1.05,1.05,0,0,0,1.562-1.134l-1.271-5.6L19.391,8.76A1.05,1.05,0,0,0,19.7,7.644Zm0,0'
            transform='translate(0 -0.491)'
            fill='#ffc107'
          />
        </g>
        <g transform='translate(479.625 8988.372)'>
          <path
            d='M19.7,7.644a1.048,1.048,0,0,0-.9-.722l-5.7-.518L10.841,1.128a1.05,1.05,0,0,0-1.931,0L6.656,6.4l-5.7.518a1.051,1.051,0,0,0-.6,1.837l4.309,3.779L3.4,18.135a1.048,1.048,0,0,0,1.561,1.134L9.875,16.33l4.915,2.939a1.05,1.05,0,0,0,1.562-1.134l-1.271-5.6L19.391,8.76A1.05,1.05,0,0,0,19.7,7.644Zm0,0'
            transform='translate(0 -0.491)'
            fill='#ffc107'
          />
        </g>
      </g>
    </svg>
  );
}

export default StarsIcon;
