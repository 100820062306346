import React from 'react';
import { doesItAll, doesItAllWrap } from './doesItAll.module.scss';

function DoesItAll() {
  return (
    <section className={`bg-indigo ${doesItAll}`}>
      <div className={doesItAllWrap}>
        <h2>Daily Doc does it all</h2>
        <p className='medium-font-heavy'>
          Daily Doc provides secure Messaging between doctors, nurses and all
          other hospital staff. With Daily Doc avoid too many phone calls and
          non stop interruptions. With this Daily Doc decreases erros and
          imrpoves outcomes.
        </p>
      </div>
    </section>
  );
}

export default DoesItAll;
