import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CheckIcon from '../icons/checkIcon';

import {
  giffSection,
  evenGiffBox,
  giffSketch,
  giffWrap,
  evenHeadingWrap,
  checkIcon,
} from './giff.module.scss';

function Giff2() {
  return (
    <section className={`bg-indigo ${giffSection}`}>
      <div className={evenGiffBox}>
        <div className={giffWrap}>
          <StaticImage
            className={giffSketch}
            srcSet='../../images/hospital-directory@3x.png 3x, ../../hospital-directory@2x.png 2x, ../../hospital-directory@1x.png 1x '
            src='../../images/hospital-directory.png'
            alt='hospital directory image'
          />
        </div>
        <div className={evenHeadingWrap}>
          <CheckIcon className={checkIcon} />
          <h2>Hospital Directory</h2>
          <p className='medium-font-heavy'>
            Search through entire hospital directory and get hold of anyone
            working in the hospital instantly. Send the person a secure message
            or give a call.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Giff2;
