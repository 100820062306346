import React from 'react';

function CheckIcon({ className }) {
  return (
    <svg
      className={className}
      width='61px'
      height='61px'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      xmlnssvgjs='http://svgjs.com/svgjs'
      version='1.1'
      x='0'
      y='0'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      className=''
    >
      <g>
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0'
          fill='#1aa6e1'
          data-original='#000000'
          className=''
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m232 341.332031c-4.097656 0-8.191406-1.554687-11.308594-4.691406l-69.332031-69.332031c-6.25-6.253906-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l58.023437 58.027344 127.363281-127.359375c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.632812l-138.667969 138.667969c-3.15625 3.136719-7.25 4.691406-11.347656 4.691406zm0 0'
          fill='#1aa6e1'
          data-original='#000000'
          className=''
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
